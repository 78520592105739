import {
	Path as EpicUiPath,
	PathBase,
	PathBaseWithId,
	PathWithId,
} from "@thekeytechnology/epic-ui";
import { CoachPath } from "@router/paths/coach";
import { DocumentsPath } from "@router/paths/documents";
import { ContentSubmissionPath } from "./content-submission";
import { ProfileMenuPath } from "./profile";
import { RootPath } from "./root";
import { ShoppingCartPath } from "./shopping-cart";

export * from "./root";
export * from "./content-submission";
export * from "./shopping-cart";

export class LoginPath extends PathBase {
	static readonly pathKey = "login";
}

export class SignUpPath extends PathBase {
	static readonly pathKey = "signup";
}

export class ForgotPasswordPath extends PathBase {
	static readonly pathKey = "forgotPassword";
	static readonly pathName = "forgot-password";
}

export class ResetPasswordPath extends PathBaseWithId {
	static readonly pathKey = "resetPassword";
	static readonly pathName = "reset-password";
	static readonly idKey = "token";
}

export class SuccessPath extends PathBase {
	static readonly pathKey = "success";
}

export class ErrorPath extends PathBase {
	static readonly pathKey = "error";
}

export class ActivatePath extends PathWithId<typeof ActivatePath> {
	static readonly pathKey = "activate";
	static readonly idKey = "token";
	static readonly childPaths = [SuccessPath, ErrorPath];
}

export class AcceptInvitationPath extends PathWithId<typeof AcceptInvitationPath> {
	static readonly pathKey = "acceptInvitation";
	static readonly pathName = "accept-invitation";
	static readonly idKey = "token";
	static readonly childPaths = [SuccessPath, ErrorPath];
}

export class ProgressionPath extends PathBase {
	static readonly pathKey = "progression";
}

export class CreateOrderPath extends PathBaseWithId {
	static readonly pathKey = "createOrder";
	static readonly pathName = "create-order";
	static readonly idKey = "createOrderJson";
}

export class AppointmentsPath extends PathBase {
	static readonly pathKey = "appointments";
}

export class BookAppointmentPath extends PathBaseWithId {
	static readonly pathKey = "bookAppointment";
	static readonly pathName = "book-appointment";
	static readonly idKey = "coachingId";
}

export class CoachingPath extends PathBaseWithId {
	static readonly pathKey = "coaching";
	static readonly idKey = "coachingId";
}

export class AvailabilitiesPath extends PathBase {
	static readonly pathKey = "availabilities";
}

export class OrientationPath extends PathBase {
	static readonly pathKey = "orientation";
}

export class PlacementPath extends PathBase {
	static readonly pathKey = "placement";
}
export class TrendsDetailPath extends PathBaseWithId {
	static readonly pathKey = "detail";
	static readonly pathName = "detail";
	static readonly idKey = "trendId";
}

export class TrendsPath extends EpicUiPath<typeof TrendsPath> {
	static readonly pathKey = "trends";
	static readonly pathName = "trends";
	static readonly childPaths = [TrendsDetailPath];
}

export class DashboardPath extends PathBase {
	static readonly pathKey = "dashboard";
}

export class AvgsPotentialAnalysisPath extends PathWithId<typeof AvgsPotentialAnalysisPath> {
	static readonly pathKey = "avgsPotentialAnalysis";
	static readonly pathName = "avgs-potential-analysis";
	static readonly idKey = "potentialAnalysisId";
	static readonly childPaths = [];
}

export class PathIndex extends EpicUiPath<typeof PathIndex> {
	static readonly childPaths = [
		LoginPath,
		SignUpPath,
		ForgotPasswordPath,
		ResetPasswordPath,
		ActivatePath,
		AcceptInvitationPath,
		ProgressionPath,
		ShoppingCartPath,
		CreateOrderPath,
		RootPath,
		CoachPath,
		ContentSubmissionPath,
		DocumentsPath,
		ProfileMenuPath,
		AppointmentsPath,
		BookAppointmentPath,
		CoachingPath,
		AvailabilitiesPath,
		OrientationPath,
		PlacementPath,
		TrendsPath,
		TrendsDetailPath,
		DashboardPath,
		AvgsPotentialAnalysisPath,
	];
}

export const Path = new PathIndex();
