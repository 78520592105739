import styled from "styled-components";
import tw from "twin.macro";
import { cva } from "@styled-system/css";

export const wrapperClass = cva({
	base: {
		h: "[100%]",
		display: "grid",
		bg: "shade.5",
	},
	variants: {
		isVisibleNavbar: {
			true: {
				gridTemplateRows: "[1fr min-content]",
				xl: {
					gridTemplateColumns: "[min-content 1fr]",
				},
			},
		},
	},
});

export const NavbarWrapper = tw.div`
	row-start-2
	row-end-2
	xl:row-auto
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	xl:shadow-none
	z-10
`;

export const screenWrapperClass = cva({
	base: {
		gridRowStart: 1,
		gridRowEnd: 1,
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		xl: {
			gridRow: "[auto]",
			paddingLeft: "24",
			paddingRight: "40",
		},
		"3xl": {
			width: "[85%]",
		},
	},
	variants: {
		singlePageMode: {
			true: {
				mx: "auto",
				lg: {
					w: "[57.5rem!]",
					maxW: "[57.5rem!]",
				},
			},
		},
	},
});

export const ScrollWrapper = tw.div`
	overflow-y-auto
	xl:overflow-hidden
	flex-1
	flex
	flex-col
`;

export const ChildrenWrapper = styled.div<{ noPadding: boolean }>`
	${tw`
		xl:overflow-y-auto
		flex-1
		bg-shade-0
		rounded-tl-24
		rounded-tr-24
		xl:ml-24
		p-24
		md:p-40
	`}
	${({ noPadding }) => (noPadding ? tw`p-0 md:p-0` : "")}
`;

export const BottomContentWrapper = tw.div`
	flex-none
	z-10
	bg-shade-0
	shadow-brand-main-100/10
	shadow-[0rem_-0.125rem_0.625rem_0rem]
	xl:ml-24
`;
