import { MiddlewareNextFn, RelayRequestAny } from "react-relay-network-modern";
import { ERROR_MESSAGES } from "@i18n/ERROR_MESSAGES";
import { logout } from "@slices/AuthSlice";
import { ReduxStore } from "@store/index";

const LOGOUT_ERRORS = ["auth_refresh_token_expired", "auth_jwt_malformed"].map((e) =>
	e.toLowerCase(),
);

export const ErrorHandlingMiddleware =
	(
		next: MiddlewareNextFn,
		showErrorToast: (errorMessage: string) => void,
		t: (label: string) => string,
	) =>
	async (req: RelayRequestAny) => {
		const res = await next(req);
		const errors = res.errors?.map((e) => e.message?.toLowerCase()) || [];
		const needsToLogout = LOGOUT_ERRORS.find((le) => errors.includes(le));
		if (needsToLogout) {
			ReduxStore.dispatch(logout());
		} else {
			errors?.forEach((e) => {
				const message = ERROR_MESSAGES[e] || e;
				showErrorToast(t(message));
			});
		}

		return res;
	};
