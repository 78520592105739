/**
 * @generated SignedSource<<9dab133b3942290388a46ab0fe25d623>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginJwtInput = {
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type useAuthContext_LoginMutation$variables = {
  input: LoginJwtInput;
};
export type useAuthContext_LoginMutation$data = {
  readonly Auth: {
    readonly loginJwt: {
      readonly loginResult: {
        readonly extension: {
          readonly forwardToFrontendURL?: string | null;
          readonly isAVGSClient?: boolean;
        };
        readonly firstLogin: boolean;
        readonly jwtTokens: {
          readonly accessToken: string;
          readonly refreshToken: string;
        };
      };
    } | null;
  };
};
export type useAuthContext_LoginMutation = {
  response: useAuthContext_LoginMutation$data;
  variables: useAuthContext_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "JwtLoginInformation",
  "kind": "LinkedField",
  "name": "jwtTokens",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accessToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refreshToken",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstLogin",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAVGSClient",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "forwardToFrontendURL",
      "storageKey": null
    }
  ],
  "type": "ConstellationLoginResultExtension",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAuthContext_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginJwtPayload",
            "kind": "LinkedField",
            "name": "loginJwt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LoginResult",
                "kind": "LinkedField",
                "name": "loginResult",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAuthContext_LoginMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthMutations",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "LoginJwtPayload",
            "kind": "LinkedField",
            "name": "loginJwt",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LoginResult",
                "kind": "LinkedField",
                "name": "loginResult",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "extension",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d5524e4d3643792f3d9f91a698a081f3",
    "id": null,
    "metadata": {},
    "name": "useAuthContext_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation useAuthContext_LoginMutation(\n  $input: LoginJwtInput!\n) {\n  Auth {\n    loginJwt(input: $input) {\n      loginResult {\n        jwtTokens {\n          accessToken\n          refreshToken\n        }\n        firstLogin\n        extension {\n          __typename\n          ... on ConstellationLoginResultExtension {\n            isAVGSClient\n            forwardToFrontendURL\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "99b089d10eeecee3dbe4a5e23ed6622d";

export default node;
