import { useContext } from "react";
import { useMutation } from "react-relay";
import { DialogTemplate } from "@components/dialog-template";
import { Icon } from "@components/icon";
import { ResponsiveBottomSheetModal } from "@components/responsive-bottom-sheet-modal";
import { deleteGroupsModal_DeleteUserInAccountGroupMutation } from "@relay/deleteGroupsModal_DeleteUserInAccountGroupMutation.graphql";
import { AccountContext } from "@screens/account";
import { error100 } from "@themes/colors";
import { H1Span, TkaP2Span, TkaP3Span } from "@themes/font-tags";
import { LOST_GROUPS_RIGHT } from "./delete-groups-modal.consts";
import { DELETE_USER_IN_ACCOUNT_GROUP_MUTATION } from "./delete-groups-modal.graphql";
import { Item, TitleWrapper, Wrapper } from "./delete-groups-modal.styles";
import { DeleteGroupsModalProps } from "./delete-groups-modal.types";
import { GroupsTabContext } from "../../groups-tab.context";

export const DeleteGroupsModal = ({
	isVisible,
	onDismiss,
	onSuccess,
	groupIds,
}: DeleteGroupsModalProps) => {
	const [deleteUserInAccountGroup, isDeletingUserInAccountGroup] =
		useMutation<deleteGroupsModal_DeleteUserInAccountGroupMutation>(
			DELETE_USER_IN_ACCOUNT_GROUP_MUTATION,
		);
	const { groupsConnectionIds } = useContext(AccountContext);
	const { deletableGroupIds } = useContext(GroupsTabContext);
	const handleDeleteGroupOnClick = () => {
		if (!groupIds && (!deletableGroupIds || deletableGroupIds.length < 1)) return;
		deleteUserInAccountGroup({
			variables: {
				input: {
					ids: groupIds ?? deletableGroupIds ?? [],
				},
				connectionIds: groupsConnectionIds,
			},
			onCompleted: () => onSuccess?.(),
		});
	};

	const groupsCount = deletableGroupIds?.length ?? 0;
	const multiple = groupsCount > 1;
	const groupPostfix = multiple ? "n" : "";
	return (
		<ResponsiveBottomSheetModal isVisible={isVisible} dismissable={false} onDismiss={onDismiss}>
			<DialogTemplate
				secondaryButtonLabel="Abbrechen"
				onSecondaryButtonClick={onDismiss}
				primaryButtonLabel={`Gruppe${groupPostfix} löschen`}
				primaryButtonColorVersion="error"
				onPrimaryButtonClick={handleDeleteGroupOnClick}
				primaryButtonLoading={isDeletingUserInAccountGroup}
			>
				<Wrapper>
					<TitleWrapper>
						<H1Span>Gruppe{groupPostfix} wirklich löschen?</H1Span>
						{multiple && <TkaP3Span>{groupsCount}x Gruppen ausgewählt</TkaP3Span>}
					</TitleWrapper>
					<TkaP2Span>
						Durch das Löschen der Gruppe{groupPostfix} gehen dir die folgenden Daten für
						immer verloren und können nicht wiederhergestellt werden:
					</TkaP2Span>
					{LOST_GROUPS_RIGHT.map((lostGroupRight) => (
						<Item key={lostGroupRight}>
							<Icon icon="wrong" sizeInRem={1.25} tkaColor={error100} />
							<TkaP2Span>{lostGroupRight}</TkaP2Span>
						</Item>
					))}
				</Wrapper>
			</DialogTemplate>
		</ResponsiveBottomSheetModal>
	);
};
