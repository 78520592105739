import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment accountTab_QueryFragment on Query {
		AccountBaseData {
			AccountBaseData {
				__typename
				...businessAccountForm_BaseDataFragment
				...privateAccountForm_BaseDataFragment
			}
		}
		Viewer {
			Auth {
				...accountContextMenu_AuthViewerSchemaFragment
				currentUser {
					accounts {
						groupAssociations {
							group {
								name
							}
						}
						name
						id
					}
				}
			}
		}
	}
`;

export const EDIT_BUSINESS_BASE_DATA_MUTATION = graphql`
	mutation accountTab_EditBusinessBaseDataMutation($input: EditBusinessBaseDataInput!) {
		AccountBaseData {
			editBusinessBaseData(input: $input) {
				baseData {
					__typename
					...businessAccountForm_BaseDataFragment
					...privateAccountForm_BaseDataFragment
				}
			}
		}
	}
`;

export const EDIT_PRIVATE_BASE_DATA_MUTATION = graphql`
	mutation accountTab_EditPrivateBaseDataMutation($input: EditPrivateBaseDataInput!) {
		AccountBaseData {
			editPrivateBaseData(input: $input) {
				baseData {
					__typename
					...businessAccountForm_BaseDataFragment
					...privateAccountForm_BaseDataFragment
				}
			}
		}
	}
`;

export const SET_ACCOUNT_NAME_MUTATION = graphql`
	mutation accountTab_SetAccountNameMutation($input: SetAccountNameUserInput!) {
		Auth {
			setAccountNameUser(input: $input) {
				account {
					name
				}
			}
		}
	}
`;
