/**
 * @generated SignedSource<<36445cd83effe3727690d4dbfc1ddf15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bookAppointment_GetCoachingQuery$variables = {
  id: string;
};
export type bookAppointment_GetCoachingQuery$data = {
  readonly node: {
    readonly associatedCoaches?: ReadonlyArray<{
      readonly coach: {
        readonly id: string;
      } | null;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"dropdownCoach_CoachProfileFragment">;
    }>;
    readonly description?: {
      readonly description: string | null;
      readonly image: {
        readonly url: string | null;
      } | null;
      readonly name: string;
    } | null;
    readonly licenseAvailability?: {
      readonly numAvailable?: number;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"bookAppointment_QueryFragment">;
};
export type bookAppointment_GetCoachingQuery = {
  response: bookAppointment_GetCoachingQuery$data;
  variables: bookAppointment_GetCoachingQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numAvailable",
      "storageKey": null
    }
  ],
  "type": "LicenseAvailabilityFreeLicensesAvailable",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "bookAppointment_GetCoachingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingOfferDescription",
                "kind": "LinkedField",
                "name": "description",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachProfile",
                "kind": "LinkedField",
                "name": "associatedCoaches",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "coach",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "dropdownCoach_CoachProfileFragment",
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "coach",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "dropdownUser_UserFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v2/*: any*/),
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "userAvatar_UserFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "licenseAvailability",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CoachingOffer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "bookAppointment_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "bookAppointment_GetCoachingQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachingOfferDescription",
                "kind": "LinkedField",
                "name": "description",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CoachProfile",
                "kind": "LinkedField",
                "name": "associatedCoaches",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "coach",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "userExtensionKind",
                            "value": "Academies"
                          }
                        ],
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "avatar",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "thumbnail",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "AcademiesUserExtension",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": "extension(userExtensionKind:\"Academies\")"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "licenseAvailability",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "CoachingOffer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b97bc697778b3abc42760f77237cff71",
    "id": null,
    "metadata": {},
    "name": "bookAppointment_GetCoachingQuery",
    "operationKind": "query",
    "text": "query bookAppointment_GetCoachingQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on CoachingOffer {\n      description {\n        name\n        description\n        image {\n          url\n          id\n        }\n      }\n      associatedCoaches {\n        id\n        coach {\n          id\n        }\n        ...dropdownCoach_CoachProfileFragment\n      }\n      licenseAvailability {\n        __typename\n        ... on LicenseAvailabilityFreeLicensesAvailable {\n          numAvailable\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment dropdownCoach_CoachProfileFragment on CoachProfile {\n  id\n  coach {\n    id\n    ...dropdownUser_UserFragment\n  }\n}\n\nfragment dropdownUser_UserFragment on User {\n  id\n  name\n  ...userAvatar_UserFragment\n}\n\nfragment userAvatar_UserFragment on User {\n  extension(userExtensionKind: Academies) {\n    __typename\n    ... on AcademiesUserExtension {\n      firstName\n      lastName\n      avatar {\n        thumbnail\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea0ffa9acad31f3acad9f8c42f01a9d9";

export default node;
