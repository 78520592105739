export const ERROR_MESSAGES: Record<string, string> = {
	auth_login_with_email_and_password_unspecified_auth:
		"loginForm:login_form.errorsUnspecifiedAuth",
	auth_login_with_token_unspecified_auth:
		"loginForm:login_form.errorsLoginWithTokenUnspecifiedAuth",
	auth_login_as_user_unspecified_auth: "loginForm:login_form.errorsLoginAsUserUnspecifiedAuth",
	auth_already_registered: "registerForm:register_form.errorsAlreadyRegistered",
	auth_user_not_activated: "forgotPasswordForm:forgot_password_form.errorsUserNotActivated",
	auth_forgot_password_unspecified_auth:
		"forgotPasswordForm:forgot_password_form.errorsUnspecifiedAuth",
	auth_invalid_reset_password_token:
		"resetPasswordForm:reset_password_form.errorsInvalidResetPasswordToken",
	auth_reset_password_unspecified_auth:
		"resetPasswordForm:reset_password_form.errorsResetPasswordUnspecifiedAuth",
	auth_invalid_user_activation_token:
		"activateScreen:activate_screen.errorsInvalidUserActivationToken",
	auth_user_activation_token_expired:
		"activateScreen:activate_screen.errorsUserActivationTokenExpired",
	auth_activate_user_unspecified_auth:
		"activateScreen:activate_screen.errorsActivateUserUnspecifiedAuth",
	auth_resend_activation_unspecified_auth:
		"activateScreen:activate_screen.errorsResendActivationUnspecifiedAuth",
	auth_cannot_leave_account_as_last_owner:
		"Konto verlassen als einziger Manager ist nicht möglich.",
	auth_email_invalid: "Email ist ungültig oder existiert nicht.",
};
