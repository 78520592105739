import { useEffect, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@components/avatar";
import { Icon } from "@components/icon";
import { MostRecentLearnableCard } from "@components/most-recent-learnable-card";
import { usePermissions } from "@hooks/use-permissions";
import { ScreenWithNavbarLayout } from "@layouts/screen-with-navbar-layout";
import { dashboard_points$key } from "@relay/dashboard_points.graphql";
import { dashboard_Query } from "@relay/dashboard_Query.graphql";
import { LearnableKind } from "@relay/gridView_Query.graphql";
import { Path } from "@router/paths";
import { H1Span, TkaH2Span, TkaH3Span } from "@themes/font-tags";
import { DashboardScreenContext, IDashboardScreenContext } from "./dashboard.context";
import { POINTS_FRAGMENT, QUERY } from "./dashboard.graphql";
import {
	CardsWrapper,
	Header,
	LearnableGridHeader,
	PointsWrapper,
	UserWrapper,
	Wrapper,
} from "./dashboard.styles";
import { Filters } from "./parts/filter";
import { LearnableGrid } from "./parts/learnableGrid";

export const DashboardScreen = () => {
	const { isBusinessAccount } = usePermissions();
	const data = useLazyLoadQuery<dashboard_Query>(QUERY, { kinds: [] });
	const userExtension = data?.Viewer?.Auth?.currentUser?.user?.extension;
	const points = useFragment<dashboard_points$key>(POINTS_FRAGMENT, userExtension ?? null);
	const userName = data?.Viewer?.Auth?.currentUser?.user?.name;
	const firstName = data?.Viewer?.Auth?.currentUser?.user?.extension?.firstName;
	const lastName = data?.Viewer?.Auth?.currentUser?.user?.extension?.lastName;
	const avatarUrl = data?.Viewer?.Auth?.currentUser?.user?.extension?.avatar?.url;
	const [state, setState] = useState<Omit<IDashboardScreenContext, "helpers">>({
		filters: {
			kinds: [
				...(isBusinessAccount ? ["GroupCoaching" as LearnableKind] : []),
				"OneToOneCoaching",
				"Course",
				"MicroLearning",
			],
			filterByCompleted: false,
		},
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (!data?.CustomerJourney?.Dashboard) {
			navigate(Path.progression.path);
		}
	}, [data]);

	const helpers = {
		filters: {
			kinds: [],
			filterByCompleted: false,
		},
		helpers: {
			setKinds: (kinds: LearnableKind[]) => {
				setState((state) => ({
					...state,
					filters: { ...state.filters, kinds },
				}));
			},
			toggleKind: (kind: LearnableKind) => {
				const {
					filters: { kinds },
				} = { ...state };
				if (kinds.includes(kind)) {
					const newKinds = [...kinds].filter((k) => k !== kind);
					setState({ ...state, filters: { ...state.filters, kinds: newKinds } });
				} else {
					setState({
						...state,
						filters: { ...state.filters, kinds: [...state.filters.kinds, kind] },
					});
				}
			},
			toggleFilterByCompleted: () => {
				setState((state) => ({
					...state,
					filters: {
						...state.filters,
						filterByCompleted: !state.filters.filterByCompleted,
					},
				}));
			},
		},
	};
	const contextValue: IDashboardScreenContext = { ...state, helpers: helpers.helpers };

	return (
		<DashboardScreenContext.Provider value={contextValue}>
			<ScreenWithNavbarLayout>
				<Wrapper>
					<Header>
						<H1Span>Willkommen!</H1Span>
						<PointsWrapper>
							<TkaH3Span bold>{points?.sumGamificationPoints}</TkaH3Span>
							<Icon icon="coinStar" sizeInRem={1} />
						</PointsWrapper>
					</Header>
					<CardsWrapper>
						<UserWrapper>
							<Avatar
								sizeInRem={5}
								firstName={firstName}
								lastName={lastName}
								imgURL={avatarUrl}
							/>
							<H1Span>{userName}</H1Span>
						</UserWrapper>
						<MostRecentLearnableCard
							queryFragmentRef={data?.CustomerJourney?.Dashboard?.mostRecentLearnable}
						/>
					</CardsWrapper>
					<LearnableGridHeader>
						<TkaH2Span>Deine Weiterentwicklung</TkaH2Span>
						<Filters />
					</LearnableGridHeader>
					<LearnableGrid queryFragmentRef={data} />
				</Wrapper>
			</ScreenWithNavbarLayout>
		</DashboardScreenContext.Provider>
	);
};
